<template>
  <div class="box">
    <el-tabs v-model="tabIdx" @tab-click="chooseTab">
      <el-tab-pane label="基本信息" name="0"></el-tab-pane>
      <el-tab-pane label="发票功能" name="1"></el-tab-pane>
      <el-tab-pane label="客服功能" name="2"></el-tab-pane>
    </el-tabs>
    <el-form :model="info" label-position="right" :rules="rules" ref="ruleForm" label-width="100px" class="ruleForm" size="small">
      <div v-if="tabIdx == 0">
        <el-form-item label="商户名称" prop="title">
          <el-input v-model="info.title" placeholder="请输入商户名称"></el-input>
        </el-form-item>
        <el-form-item label="商户LOGO" prop="logo">
          <SelectImgs :selectNum="1" :selectData="[info.logo]" :selectFunc="picChoose" :idx="1"></SelectImgs>
        </el-form-item>
        <el-form-item label="联系人">
          <el-input v-model="info.contact_name" placeholder="请输入联系人"></el-input>
        </el-form-item>
        <el-form-item label="联系人手机">
          <el-input v-model="info.contact_mobile" placeholder="请输入联系人手机"></el-input>
        </el-form-item>
      </div>
      <div v-if="tabIdx == 1">
        <el-form-item label="发票功能" prop="is_open_invoice">
          <el-switch
              v-model="config.is_open_invoice"
              active-value="1"
              inactive-value="0"
              active-text="打开"
              inactive-text="关闭">
          </el-switch>
        </el-form-item>
        <el-form-item label="专用发票功能" prop="is_open_special_invoice">
          <el-switch
              v-model="config.is_open_special_invoice"
              active-value="1"
              inactive-value="0"
              active-text="打开"
              inactive-text="关闭">
          </el-switch>
        </el-form-item>
      </div>
      <div v-if="tabIdx == 2">
        <el-form-item label="退款理由" prop="refund_reason">
          <el-input
              type="textarea"
              rows="8"
              placeholder="请输入理由，每行一个"
              v-model="config.refund_reason">
          </el-input>
        </el-form-item>
        <el-form-item label="退货理由" prop="return_reason">
          <el-input
              type="textarea"
              rows="8"
              placeholder="请输入理由，每行一个"
              v-model="config.return_reason">
          </el-input>
        </el-form-item>
        <el-form-item label="客服类型" prop="kefu_type">
          <el-radio-group v-model="config.kefu_type">
            <el-radio :label="1">平台客服</el-radio>
            <el-radio :label="2">系统客服</el-radio>
            <el-radio :label="3">电话客服</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="客服电话" v-if="config.kefu_type==3">
          <el-input v-model="config.kefu_mobile" placeholder="请输入电话"></el-input>
        </el-form-item>
      </div>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>

  import SelectImgs from '../../components/SelectImgs'
  export default {
    data() {
      return {
        info: {
          title: '',
          contact_name: '',
          contact_mobile: '',
          logo: ''
        },
        config: {
          is_open_invoice: '',
          is_open_special_invoice: '',
          kefu_type: '',
          kefu_mobile: '',
          refund_reason: '',
          return_reason: ''
        },
        source_domain: this.config.SOURCE_DOMAIN,
        rules: {
          title: [
            { required: true, message: '请输入标题', trigger: 'blur' }
          ]
        },
        tabIdx: 0
      };
    },
    components: {
      SelectImgs
    },
    created() {
      this.getInfo()
      this.getConfigInfo()
    },
    mounted() {
    },
    computed: {
    },
    methods: {
      chooseTab(e) {
        this.getConfigInfo()
      },
      getInfo() {
        this.$api.merchant.sysDetail( res => {
          if(res.errcode == 0) {
            this.info = res.data
          }
        })
      },
      getConfigInfo() {
        this.$api.merchant.sysConfigDetail( res => {
          if(res.errcode == 0) {
            this.config = res.data
            this.config.is_open_invoice = String(this.config.is_open_invoice)
            this.config.is_open_special_invoice = String(this.config.is_open_special_invoice)
          }
        })
      },
      picChoose(data) {
        this.info.logo = data.pic
      },
      submitForm(formName) {
        var _this = this
        this.$refs[formName].validate((valid) => {
          if (valid) {
            var param = {}
            if(_this.tabIdx==0) {
              param = {
                contact_name: _this.info.contact_name,
                contact_mobile: _this.info.contact_mobile,
                logo: _this.info.logo
              }
              this.$api.merchant.sysEdit(param,function (res) {
                if(res.errcode == 0) {
                  _this.success(res.errmsg)
                } else {
                  _this.fail(res.errmsg)
                }
              })
            } else if(_this.tabIdx==1) {
              param = {
                is_open_invoice: _this.config.is_open_invoice,
                is_open_special_invoice: _this.config.is_open_special_invoice
              }
              this.$api.merchant.sysConfigEdit(param,function (res) {
                if(res.errcode == 0) {
                  _this.success(res.errmsg)
                } else {
                  _this.fail(res.errmsg)
                }
              })
            } else if(_this.tabIdx==2) {
              param = {
                is_open_invoice: _this.config.kefu_type,
                kefu_mobile: _this.config.kefu_mobile,
                refund_reason: _this.config.refund_reason,
                return_reason: _this.config.return_reason,
              }
              this.$api.merchant.sysConfigEdit(param,function (res) {
                if(res.errcode == 0) {
                  _this.success(res.errmsg)
                } else {
                  _this.fail(res.errmsg)
                }
              })
            }
          } else {
            return false;
          }
        });
      },
    }
  };
</script>
<style scoped>
  .box {
    background-color: #fff;
    padding: 20px;
    border-radius: 6px;
    min-height: 500px;
  }
  .ruleForm {
    width: 600px;
  }
</style>
